/* -------------------------------------------------------------
Buscar
------------------------------------------------------------- */
img{width: 100%;}

/* -------------------------------------------------------------
Typography
------------------------------------------------------------- */

$family-sans-serif: 'Montserrat', sans-serif;
$family-serif: 'Bernier', serif;

@font-face {
  font-family: 'Montserrat';
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,700&display=swap');
}

@font-face{
  font-family: 'Poppins';
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;900&display=swap');
}
// @font-face{
//   font-family: 'Galano';
//   font-style: normal;
//   font-weight: 800;
//   src: url("/fonts/galano-grotesque-bold.otf");
// }


/* -------------------------------------------------------------
Colours
------------------------------------------------------------- */

$darkblue: #152C39;
$lightblue: #0052B5;
$gold: #DEAB5F;
//  #A97F10
$bggrey: #EEEADE;

$primary: $darkblue;
$secondary: $gold;

/* -------------------------------------------------------------
Bootstrap imports
------------------------------------------------------------- */

@import '~bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

/* -------------------------------------------------------------
Colour theme
------------------------------------------------------------- */

$theme-colors: map-merge(
  (
    'primary':    $primary,
    'secondary':  $secondary,
    'success':    $success,
    'info':       $info,
    'warning':    $warning,
    'danger':     $danger,
    'light':      $light,
    'dark':       $dark,
  ),
  $theme-colors
);

.card{
  border:none!important;
}
.btn-link:hover{
  text-decoration: none!important;
  color: $lightblue!important;
}
.btn:focus{
  box-shadow: none!important;
}
.card-header{
  border-bottom: 1px solid $lightblue!important;
  padding: 0.1rem 1.25rem !important;
}
.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background: none!important;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }
}
.whatsapp{
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  z-index: 99;
  img{
    width: 100%;
  }
}